import "$styles/index.css"
import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components

import ContentLoader from 'stimulus-content-loader'

import components from "$components/**/*.{js,jsx,js.rb,css}"

import { Application } from "@hotwired/stimulus"
import HelloController from "./controllers/hello_controller"

window.Stimulus = Application.start()
Stimulus.register("hello", HelloController)
Stimulus.register('content-loader', ContentLoader)

console.info("XBridgetown is loaded!")
